import cx from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { cloneElement, forwardRef, useEffect, useMemo, useState } from 'react';

import { BadgeNumber } from './Badge';

import styles from './Button.module.css';

export type ButtonVariant =
  | 'alertPrimary'
  | 'alertSecondary'
  | 'blackPrimary'
  | 'blackSecondary'
  | 'gray'
  | 'grayDark'
  | 'link'
  | 'primary'
  | 'secondary'
  | 'success';

type ButtonSize = 'extraSmall' | 'small' | 'medium';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  badgeNumber?: number;
  buttonChildrenClassName?: string;
  endIcon?: React.ReactElement;
  isFullWidth?: boolean;
  label?: string;
  size?: ButtonSize;
  startIcon?: React.ReactElement;
  variant?: ButtonVariant;
  /** Hold action. If it returns a promise then will automatically release when resolved */
  onHold?: () => void | Promise<void>;
  onRelease?: () => void;
  isPulseDisabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    badgeNumber,
    buttonChildrenClassName,
    children,
    className,
    disabled,
    endIcon,
    isFullWidth,
    onHold,
    onRelease,
    label,
    size = 'medium',
    startIcon,
    type = 'button',
    variant = 'primary',
    isPulseDisabled = false,
    ...other
  } = props;

  const [isHolding, setIsHolding] = useState<boolean>(false);

  const StartIconComponent = useMemo(() => {
    return (
      startIcon &&
      cloneElement(startIcon, {
        className: cx(styles.icon, startIcon.props.className),
      })
    );
  }, [startIcon]);

  const EndIconComponent = useMemo(() => {
    return (
      endIcon &&
      cloneElement(endIcon, {
        className: cx(styles.icon, endIcon.props.className),
      })
    );
  }, [endIcon]);

  const isHoldable = !disabled && Boolean(onHold || onRelease);

  useEffect(() => {
    // Set not-holding when button becomes disabled
    if (!isHoldable) {
      setIsHolding(false);
    }
  }, [isHoldable]);

  useEffect(() => {
    // Occasionally in Safari an orientation change appears to stop a pointer event firing
    // Also in Safari, if you switch tab while holding the button, neither pointerup nor pointercancel
    // events are fired. Listen to document visibility change for this case.

    const setIsNotHolding = () => setIsHolding(false);

    document.addEventListener('visibilitychange', setIsNotHolding);
    // orientationchange is deprecated, but screen.orientation only available in Safari 16.4+
    window.addEventListener('orientationchange', setIsNotHolding);
    window.screen.orientation?.addEventListener('change', setIsNotHolding);

    return () => {
      document.removeEventListener('visibilitychange', setIsNotHolding);
      window.removeEventListener('orientationchange', setIsNotHolding);
      window.screen.orientation?.removeEventListener('change', setIsNotHolding);
    };
  }, []);

  useEffect(
    () => {
      // Invoke onHold when holding starts
      // Invoke onRelease when holding ends (i.e. pointer up or button becomes not-holdable)
      if (isHolding) {
        let isReleased = false;

        onHold?.()?.finally?.(() => {
          if (!isReleased) {
            // if still holding when the hold action is resolved, then auto release
            setIsHolding(false);
          }
        });

        return () => {
          isReleased = true;
          onRelease?.();
        };
      }

      return undefined;
    },
    // don't run effect (or teardown) on change of onRelease/onHold
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHolding],
  );

  const handlePointerUp = () => {
    setIsHolding(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <button
      ref={ref}
      aria-label={label}
      className={cx(
        styles[size],
        styles.button,
        styles[variant],
        isFullWidth && styles.fullWidth,
        !children && !(startIcon && endIcon) && styles.iconButton,
        className,
      )}
      disabled={disabled}
      type={type}
      onPointerDown={(e) => {
        if (isHoldable && e.buttons === 1) {
          e.currentTarget.setPointerCapture(e.pointerId);
          setIsHolding(true);
        }
      }}
      onPointerUp={handlePointerUp}
      onPointerCancel={handlePointerUp}
      onPointerLeave={handlePointerUp}
      onPointerMove={(event) => {
        const { left, right, top, bottom } =
          event.currentTarget.getBoundingClientRect();

        // should invoke onRelease when user moves the touch a short distance away from the button
        const MARGIN = 10;

        if (
          event.clientX < left - MARGIN ||
          event.clientX > right + MARGIN ||
          event.clientY < top - MARGIN ||
          event.clientY > bottom + MARGIN
        ) {
          setIsHolding(false);
        }
      }}
      onLostPointerCapture={handlePointerUp}
      {...other}
    >
      {StartIconComponent}

      {badgeNumber && (
        <BadgeNumber className={styles.badgeNumber} isCircle size="medium">
          {badgeNumber}
        </BadgeNumber>
      )}

      {children && (
        <span
          className={cx(
            styles.buttonChildren,
            startIcon && styles.startIcon,
            endIcon && styles.endIcon,
            buttonChildrenClassName,
          )}
        >
          {children}
        </span>
      )}

      {EndIconComponent}

      {isHolding && !isPulseDisabled && (
        <div className={styles.pulseIndicator} />
      )}
    </button>
  );
});

export default Button;
