import type { OnRobot3FG15Command } from '../Command';
import type { OR3FG15FingerPosition } from '../constants';
import {
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_OFFSETS,
  OR_3FG15_TO_FINGER_OFFSET,
} from '../constants';

export function calculateOR3FG15FingerAngle({
  fingerPosition,
  fingerLength = OR_3FG15_DEFAULT_FINGER_LENGTH,
  fingertipDiameter,
  diameter,
  gripKind,
}: {
  fingerPosition: OR3FG15FingerPosition;
  fingerLength?: number;
  fingertipDiameter: number;
  // the diameter of the grip in meters
  diameter: number;
  gripKind: OnRobot3FG15Command['gripKind'];
  actuationKind?: 'move' | 'grip';
}): number {
  let rawDiameter: number;

  if (gripKind === 'inward') {
    rawDiameter = diameter + fingertipDiameter;
  } else {
    rawDiameter = diameter - fingertipDiameter;
  }

  // determine offset from finger length and position
  const offset =
    fingerLength -
    OR_3FG15_DEFAULT_FINGER_LENGTH +
    OR_3FG15_OFFSETS[fingerPosition];

  const radius = Math.max(0, rawDiameter / 2);

  // Law of Cosines for SSS triangle
  let cosAngle =
    (offset ** 2 + OR_3FG15_TO_FINGER_OFFSET ** 2 - radius ** 2) /
    (2.0 * offset * OR_3FG15_TO_FINGER_OFFSET);

  // Ensure rounding errors aren't slightly outside [-1,1]
  cosAngle = Math.min(1.0, cosAngle);
  cosAngle = Math.max(-1.0, cosAngle);

  let angle = Math.PI - Math.acos(cosAngle);

  if (angle < 0) {
    angle += 2 * Math.PI;
  }

  angle = Math.min(Math.PI, angle);

  return angle;
}
